<template>
  <header v-if="mobileHeader" class="mobile">
    <ul class="nav">
      <li class="nav-item" v-for="(page, index) in pages" :key="index" v-if="page.showMobile && $isMobileScreen">
        <router-link :class="['nav-link',isActive(page.name)]" :to="page.link">
          <span v-if="page.useMatIcon" class="material-icons">{{ page.icon }}</span>
          <img v-if="!page.useMatIcon" class="eck-logo" src="/assets/logo/LogoECK-sw.png">
          {{ page.name }} 
        </router-link>
      </li>

      <li class="nav-item">
        <router-link :class="['nav-link','bold']" :to="'/mehr'">
          <span class="material-icons">more_vert</span>
          Mehr
        </router-link>
      </li>

    </ul>
  </header>

  <header v-else :class="['fixed-top', sticky ? 'sticky' : '']">
    <nav class="navbar nav-expand-lg">
      <div class="container-fluid">

        <!-- nav brand -->
        <div class="d-flex align-items-center">
          <router-link class="navbar-brand p-0" to="/">
            <img src="/assets/logo/logo-primary.png" alt="Tripmeister">
          </router-link>
        </div> 

        <!-- nav items -->
        <div class="nav navbar-collapse" id="navbar">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-for="(page, index) in pages" :key="index">
              <router-link :class="['nav-link',isActive(page.name)]" :to="page.link">{{ page.name }}</router-link>
            </li>

            <li class="nav-item">
              <a href="#/#!" class="router-link-exact router-link nav-link" aria-current="page"><span class="material-icons-outlined">more_vert</span> Mehr </a>
              <ul class="dropdown">

                <li v-if="userLoggedIn" class="dropdown-navitem">
                  <router-link class="dropdown-navitem__link" :to="{ name: 'Profile'}">
                    <div class="icon">
                      <span class="material-icons-outlined">person</span>
                    </div>
                    Mein Profil
                    <span class="material-icons ml-auto">navigate_next</span>
                  </router-link>                
                </li>
                <li class="dropdown-navitem">
                  <a class="dropdown-navitem__link feedback" @click="showFeedbackModal">
                    <div class="icon">
                      <span class="material-icons-outlined">feedback</span>
                    </div>
                    Feedback
                    <span class="material-icons ml-auto">navigate_next</span>
                  </a>                
                </li>
                <li class="dropdown-navitem seprator" style="display:none;">
                  <router-link class="dropdown-navitem__link" :to="{ name: 'search'}">
                    <div class="icon">
                      <span class="material-icons-outlined">search</span>
                    </div>
                    Suche
                    <span class="material-icons ml-auto">navigate_next</span>
                  </router-link>                
                </li>
                <li class="dropdown-navitem" style="display:none;">
                  <router-link class="dropdown-navitem__link" :to="{ name: 'AGB'}">
                    <div class="icon">
                      <span class="material-icons-outlined">description</span>
                    </div>
                    AGB
                    <span class="material-icons ml-auto">navigate_next</span>
                  </router-link>
                </li>
                <li class="dropdown-navitem">
                  <router-link class="dropdown-navitem__link" :to="{ name: 'Nutzungsbedingungen'}">
                   <div class="icon">
                    <span class="material-icons-outlined">description</span>
                  </div>
                  Nutzungsbedingungen
                  <span class="material-icons ml-auto">navigate_next</span>
                </router-link>
              </li>
              <li class="dropdown-navitem">
                <router-link class="dropdown-navitem__link" :to="{ name: 'Datenschutz'}">
                  <div class="icon">
                    <span class="material-icons-outlined">shield</span>
                  </div>
                  Datenschutzerklärung<span class="material-icons ml-auto">navigate_next</span>
                </router-link>
              </li>
              <li class="dropdown-navitem">
                <router-link class="dropdown-navitem__link" :to="{ name: 'Impressum'}">
                 <div class="icon">
                  <span class="material-icons-outlined">policy</span>
                </div>
                Impressum
                <span class="material-icons ml-auto">navigate_next</span>
              </router-link>
            </li>
            <li v-if="!userLoggedIn" class="dropdown-navitem">
              <a class="dropdown-navitem__link" href="https://cms.tripmeister.de/#/login" target="_blank">
                <div class="icon">
                  <span class="material-icons-outlined">lock</span>
                </div>
                Login für Anbietende
                <span class="material-icons ml-auto">navigate_next</span>
              </a>                
            </li>
            <li class="dropdown-navitem">
              <a href="https://www.software-by-design.de/" class="dropdown-navitem__link" target="_blank">
                <div class="icon">
                  <img class="software-by-design-icon" :src="'/assets/logo/sbd.png'" alt="software-by-design">
                </div>Software by Design
                <span class="material-icons ml-auto">navigate_next</span></a>
              </li>
              <li v-if="!userLoggedIn" class="dropdown-navitem seprator">
                <router-link class="btn btn-active logout-btn btn-w-icon icon-left" :to="{ name: 'Login'}">
                  <span class="material-icons-outlined inline-icon">person</span>
                  <span>Login</span>
                </router-link>
              </li>
              <li v-if="userLoggedIn" class="dropdown-navitem seprator">

                <button class="btn btn-active logout-btn btn-w-icon icon-left" @click.prevent="logOut">
                  <span class="material-icons-outlined inline-icon">lock</span>
                  <span>Abmelden</span>
                </button>
              </li>

            </ul>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</header>
</template>

<script>
  import { checkIfLinkActive } from '@/utils/tripmeister-helpers';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import { screenSizeMixin } from '@/mixins/screenSizeMixin';
  import { bus } from '@/main';

  export default {
    name: 'Header',
    mixins: [screenSizeMixin],
    data: () => ({
      pages: [
        { icon: 'home', name: 'Home', link: '/', showMobile: true, useMatIcon: true },
        { icon: 'location_on', name: 'Locations', link: '/destinations/locations', showMobile: true, useMatIcon: true },
        { icon: 'local_activity', name: 'Events', link: '/destinations/events', showMobile: true, useMatIcon: true },
        { icon: 'map', name: 'entdECKungen', link: '/trips', showMobile: false, useMatIcon: true },
        { icon: 'map', name: 'Unterkünfte', link: '/unterkuenfte', showMobile: true, useMatIcon: false },
        ],
      sticky: false,
      mobileHeader: false,
      mobileHeaderWidth: 991
    }),
    mounted () {
      this.mobileHeader = window.innerWidth <= this.mobileHeaderWidth
      window.addEventListener('scroll', this.scrollHandler)
      window.addEventListener('resize', this.resizeHandle)
    },
    beforeDestroyed () {
      window.addEventListener('scroll', this.scrollHandler)
      window.addEventListener('resize', this.resizeHandle)
    },
    computed: {
      userLoggedIn() {
        if(this.$store.getters.getToken){
          return true;
        }
        return false;
      },
      currentRoute(){
        return this.$route.name;
      }
    },
    methods: {
      showLoader,
      hideLoader,
      logOut(){
        this.loader = this.showLoader(this.loader);
        this.$store.dispatch('logout');

        this.$notify({
          duration: 3500,
          title: 'Erfolgreich abgemeldet!',
          text: 'Sie haben sich vom Tripmeister abgemeldet.'
        });

        this.loader = this.hideLoader(this.loader);
        this.$router.push({name:'Home'});
      },
      showFeedbackModal() {
        bus.$emit('show-feedback-modal', true);
      },
      isActive(page){
        if(checkIfLinkActive(page,this.currentRoute)){
          return "router-link-exact-active router-link-active";
        }
        return "";
      },
      scrollHandler () {
        this.sticky = window.scrollY >= 120
      },
      resizeHandle () {
        this.mobileHeader = window.innerWidth <= this.mobileHeaderWidth
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .navbar {

    .nav {
      &-item {
        position: relative;

        &:hover {
          .dropdown {
            display: block;
          }
        }

        .dropdown {
          display: none;
          position: absolute;
          width: 290px;
          background: $white-color;
          top: 35px;
          right: 0;
          box-shadow: 0px 2px 8px 0px #00000040;
          padding: 0 15px;

          &-navitem {
            line-height: 50px;

            &:hover{
              .icon,.dropdown-navitem__link {
                color: #E5321B;
              }
            }

            &__link {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $dark-color;

              &.seprator {
                border-bottom: 1px solid rgba($color: $dark-color, $alpha: 0.2);
              }

              .icon {
                width: 24px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }

      &-link {
        display: inline-flex;
        align-items: center;

        color: $text-light;
        font-size: 16px;

        span {
          margin-right: 10px;
          color: #9e9e9e;
        }

        &.active {
          color: $secondary;
          span { color: $secondary; }
        }
      }
    }
  }

  .logout-btn {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  .btn-w-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.icon-left {
      span {
        margin-right: 10px;
      }
    }

    &.icon-right {
      span {
        margin-left: 10px;
      }
    }
  }

  .eck-logo {
    width: 23px;
  }

  .nav-link {
    align-items: center;
  }

  .feedback {
    cursor: pointer;
  }
</style>